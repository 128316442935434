import React, {useEffect, useState} from 'react';
import {InformacionSistema, PanelSistemas} from "./components";
import axios from 'axios';
import systemsData from "./Systems.json";



const Systems = ({calculate}) => {
    const [systems, setSystems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showMoreInfo, setShowMoreInfo] = useState("");

    useEffect(() => {
        axios.get('https://comparador.fundacionrenovables.org/laravel/public/api/welcome') // Cambia la URL para que incluya el puerto
            .then(response => {
                setSystems(response.data.paneles);
                setLoading(false)
            })
            .catch(error => {
                console.error('There was an error fetching the paneles!', error);
                setLoading(false)
            });
    }, []);

    useEffect(() => {
        calculate(!showMoreInfo);
    }, [showMoreInfo, calculate]);

    if (loading) {
        return <p>Cargando datos...</p>; // Renderizar algo mientras se cargan los datos
    }

    return (
        <>
            {(showMoreInfo === "") && (<PanelSistemas systems={systems} moreInfo={setShowMoreInfo}/>)}
            {(showMoreInfo !== "") && (<InformacionSistema system={systemsData[showMoreInfo]} moreInfo={setShowMoreInfo}/>)}
        </>
    );
};

export default Systems;