import React from "react";
import Sistema from "./components/sistema/Sistema";
import styles
    from "./InformacionSistema.module.css";

const InformacionSistemas = ({system,  moreInfo}) => {
    return (
        <div className={styles.module}>
            <Sistema system={system} moreInfo={moreInfo}/>
        </div>
    );
}

export default InformacionSistemas;