import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import WelcomePage from "./pages/welcome/WelcomePage";
import DemandPage from "./pages/demand/DemandPage";
import ComparePage from "./pages/compare/ComparePage"; // Ensure this import is added
import Layout from "./components/layout/Layout";
import ZeroPage from "./pages/zero/ZeroPage";

function App() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<WelcomePage />} />
                    <Route path="/demand" element={<DemandPage />} />
                    <Route path="/compare" element={<ComparePage />} />
                    <Route path="/zero" element={<ZeroPage />} />
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;