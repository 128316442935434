import React from "react";
import styles from "./HouseType.module.css";
import edificioImage from '../house-type/images/edificio.png';
import chaletImage from '../house-type/images/chalet.png';
import Opcion from "./components/opcion/Opcion";

const HouseType = ({houseType, setHouseType}) => {

    return (
        <div>
            <div className={styles.pregunta}>
                2. Un edificio plurifamiliar y una vivienda unifamiliar tienen diferentes requerimientos de
                calefacción y refrigeración. Por eso es necesario saber <b>cómo es tu vivienda</b>. Elige una de
                estas opciones:
            </div>
            <div className="row">
                <div className="col-md-3"></div>
                <Opcion
                    image={edificioImage}
                    checked={houseType === "Multifamiliar"}
                    value="Multifamiliar"
                    onChange={() => setHouseType("Multifamiliar")}
                />
                <Opcion
                    image={chaletImage}
                    checked={houseType === "Unifamiliar"}
                    value="Unifamiliar"
                    onChange={() => setHouseType("Unifamiliar")}
                />
                <div className="col-md-3"></div>
            </div>
        </div>
    );
}

export default HouseType;