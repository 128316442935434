import React from "react";
import Sistema from "../sistema/Sistema"; // Asegúrate de que este componente esté importado

const Subpanel = ({systems, moreInfo}) => {
    let rowElements = 4;
    let classDiv = "col-md-3";
    if (systems.length && systems.length < 7) {
        rowElements = 3;
        classDiv = "col-md-4";
    }
    else {
        rowElements = 4;
        classDiv = "col-md-3";
    }

    const rows = [];
    for (let i = 0; i < systems.length; i += rowElements) {
        rows.push(
            <div className="row" key={i}>
                {systems.slice(i, i + rowElements).map((system, index) => (
                    <div className={classDiv} key={index}>
                        <Sistema system={system} moreInfo={moreInfo}/>
                    </div>
                ))}
            </div>
        );
    }

    return <>{rows}</>;
}

export default Subpanel;
