import React, { useState } from 'react';
import Menu from "./components/menu/Menu";
import Seccion from "./components/seccion/Seccion"


const Panel = ({menu, panel, subpanel1, subpanel2, subpanel3, styles}) => {
    const [activeSection, setActiveSection] = useState('panel-general');

    return (
        <div className={styles.panel}>
            <Menu titles={menu} onSectionSelected={setActiveSection} styles={styles}/>
            {activeSection === 'panel-general' && <Seccion>{panel}</Seccion>}
            {activeSection === 'subpanel1' && <Seccion>{subpanel1}</Seccion>}
            {activeSection === 'subpanel2' && <Seccion>{subpanel2}</Seccion>}
            {activeSection === 'subpanel3' && <Seccion>{subpanel3}</Seccion>}
        </div>
    );
}

export default Panel;
