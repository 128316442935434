import {
    chalet,
    climaAtlantico,
    climaContinental,
    climaMediterraneo,
    edificio,
    gente,
    mas90,
    menos90
} from "../../images";

function getClimateImage(climate) {
    switch (climate) {
        case 'Atlantico':
            return climaAtlantico;
        case 'Continental':
            return climaContinental;
        case 'Mediterraneo':
            return climaMediterraneo;
        default:
            return '';
    }
}

function getHouseSurfaceImage(houseSurface) {
    switch (houseSurface) {
        case 'Menor90':
            return menos90;
        case 'Mayor90':
            return mas90;
        default:
            return '';
    }
}

function getHouseTypeImage(houseType) {
    switch (houseType) {
        case 'Unifamiliar':
            return chalet;
        case 'Multifamiliar':
            return edificio;
        default:
            return '';
    }
}

function getNumberPeopleImage() {
    return gente;
}

function getClimateText(climate) {
    switch (climate) {
        case 'Atlantico':
            return 'Clima Atlántico';
        case 'Continental':
            return 'Clima Continental';
        case 'Mediterraneo':
            return 'Clima Mediterráneo';
        default:
            return '';
    }
}

function getHouseTypeText(houseType) {
    switch (houseType) {
        case 'Unifamiliar':
            return 'Vivienda Unifamiliar';
        case 'Multifamiliar':
            return 'Vivienda Multifamiliar';
        default:
            return '';
    }
}

function getHouseSurface(houseSurface) {
    switch (houseSurface) {
        case 'Menor90':
            return 'Superficie menor a 90 m2';
        case 'Mayor90':
            return 'Superficie mayor a 90 m2';
        default:
            return '';
    }
}

function getNumberPeople(numberPeople) {
    return `Para ${numberPeople} personas`;
}

export {
    getClimateImage,
    getHouseSurfaceImage,
    getHouseTypeImage,
    getNumberPeopleImage,
    getClimateText,
    getHouseTypeText,
    getHouseSurface,
    getNumberPeople
};