import React from "react";
import styles from "./Emisiones.module.css";
import Compare from "./components/compare/Compare";

const Emisiones = ({emisiones, porcentajes}) => {
    return (
        <div className={styles.emisiones}>
            <div className={styles.title}>SEGÚN SUS EMISIONES</div>
            {porcentajes && (<Compare emissions={emisiones} percentages={porcentajes}/>)}
        </div>
    );
}

export default Emisiones;