import React from 'react';
import styles from './Objective.module.css';
import {Descubre, Edificio} from './images';
import { HashLink as Link } from 'react-router-hash-link';

const Objective = () => {
    return (
        <div className={`row ${styles.module}`}>
            <div className="col-md-6">
                <div className={`flex-column ${styles.objective}`}>
                    <div className={styles['objectiveTitleBlack']}>
                        COMPARA LOS DISTINTOS SISTEMAS
                    </div>
                    <div className={styles['objectiveTitleBlue1']}>
                        DE CALEFACCIÓN Y
                    </div>
                    <div className={styles['objectiveTitleBlue2']}>
                        AIRE ACONDICIONADO
                    </div>
                    <p>
                        Descubre cuál es el más apropiado para ti según tus necesidades y características.
                    </p>
                    <Link smooth to={"#seccion-sistemas"}>
                        <input type="image" src={Descubre} alt="Descubre"
                               width="216" height="54"/>
                    </Link>
                </div>
            </div>
            <div className="col-md-6">
                <img src={Edificio} alt="Edificio"/>
            </div>
        </div>
    );
};

export default Objective;
