import React from 'react';

const Menu = ({ titles, onSectionSelected, styles }) => {
    return (
        <>
            <div className={`row ${styles['accesoPaneles']}`} id="acceso-paneles">
                <div className="col-md-12">
                    <div className="row justify-content-center">
                        <div className={`col-md-2 ${styles['accesoPanel']}`} id="acceso-panel-general" onClick={
                            () => onSectionSelected('panel-general')
                        }>
                            {titles.title1}
                        </div>
                        <div className={`col-md-2 ${styles['accesoPanel']}`} id="acceso-subpanel1" onClick={
                            () => onSectionSelected('subpanel1')
                        }>
                            {titles.title2}
                        </div>
                        <div className={`col-md-2 ${styles['accesoPanel']}`} id="acceso-subpanel2" onClick={
                            () => onSectionSelected('subpanel2')
                        }>
                            {titles.title3}
                        </div>
                        {titles.title4 && (
                            <div className={`col-md-2 ${styles['accesoPanel']}`} id="acceso-subpanel3" onClick={
                                () => onSectionSelected('subpanel3')
                            }>
                                {titles.title4}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Menu;