function getSecciones(sections) {
    return {
        consumoCalefaccion: {
            width: sections.porcentajeEmisionesCalefaccion,
            color: "#616aa5"
        },
        consumoACS: {
            width: sections.porcentajeEmisionesACS,
            color: "#aa90c8"
        },
        consumoRefrigeracion: {
            width: sections.porcentajeEmisionesRefrigeracion,
            color: "#945a83"
        }
    };
}

export default getSecciones;