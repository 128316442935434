import React from 'react';
import styles from "./Sources.module.css";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Sources = () => {

    return (
        <div id="seccion-fuentes">
            <div className={styles.title}>Fuentes utilizadas</div>
            <div className={`row ${styles.description}`}>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    Aquí tienes en detalle las fuentes que hemos utilizado para calcular posibles soluciones que
                    cubran tu demanda de calefacción, refrigeración y agua caliente.
                </div>
                <div className="col-md-4"></div>
            </div>
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    {/* Acordeón de Equipos */}
                    <Accordion defaultExpanded sx={{backgroundColor: '#a74d65', color: 'white'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{fontWeight: 'bold'}}>+ EQUIPOS</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{backgroundColor: '#f3dce2', color: 'black'}}>
                            <Typography>
                                <ul>
                                    <li>
                                        <strong>Caldera de gasóleo.</strong> Datos obtenidos de PrecioCentro
                                        para
                                        caldera mixta instantánea chapa acero 23 kW.
                                    </li>
                                    <li>
                                        <strong>Caldera de gas.</strong> Datos obtenidos de PrecioCentro para
                                        caldera
                                        mural mixta microacumulación gas 25 kW estanca.
                                    </li>
                                    <li>
                                        <strong>Caldera de gas de condensación.</strong> Datos obtenidos de
                                        PrecioCentro
                                        para caldera mural gas mixta condensación microacumulación 25 kW.
                                    </li>
                                    <li>
                                        <strong>Aerotermia (aire-aire).</strong> Datos obtenidos de PrecioCentro
                                        para
                                        conjunto aerotermia baja temperatura altherma 3 bibloc sobrepot. mural
                                        R-32
                                        Daikin 5,75 kW - 150 l.
                                    </li>
                                    <li>
                                        <strong>Aerotermia (aire-agua).</strong> Datos obtenidos de PrecioCentro
                                        para
                                        enfriadora aire-agua inverter bomba de calor 18/20 kW.
                                    </li>
                                    <li>
                                        <strong>Aire acondicionado.</strong> Datos obtenidos de PrecioCentro
                                        para
                                        fancoil de pared 2 tubos 2,5 kW/3,5 kW.
                                    </li>
                                    <li>
                                        <strong>Termo eléctrico.</strong> Datos obtenidos de PrecioCentro para
                                        termo
                                        eléctrico ACS 50 l.
                                    </li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Acordeón de Consumos */}
                    <Accordion defaultExpanded sx={{backgroundColor: '#f4a641', color: 'white', marginTop: 2}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{fontWeight: 'bold'}}>+ CONSUMOS</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{backgroundColor: '#f7e6cb', color: 'black'}}>
                            <Typography>
                                <ul>
                                    <li><strong>Calefacción y refrigeración.</strong> Calculado a través del
                                        programa
                                        C3X para un edificio o vivienda plurifamiliar tipo para viviendas en
                                        Sevilla,
                                        Madrid y Ourense, de 80 y 110 m².
                                    </li>
                                    <li><strong>ACS.</strong> Consumo por persona y temperatura de red obtenidos
                                        a
                                        partir del CTE.
                                    </li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Acordeón de Combustibles */}
                    <Accordion defaultExpanded sx={{backgroundColor: '#4b5a91', color: 'white', marginTop: 2, marginBottom: 4}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography sx={{fontWeight: 'bold'}}>+ COMBUSTIBLES</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{backgroundColor: '#e6eaf3', color: 'black'}}>
                            <Typography>
                                <ul>
                                    <li><strong>Gasóleo.</strong> Precio actual del gasóleo obtenido de MITERD.
                                        Precios
                                        a futuro calculados con modelo propio de Fundación Renovables. Las
                                        emisiones han
                                        sido obtenidas a partir de los factores de emisiones de MITERD.
                                    </li>
                                    <li><strong>Gas natural.</strong> Precio actual del gas obtenido de MIBGAS.
                                        Precios
                                        a futuro calculados con modelo propio de Fundación Renovables. Las
                                        emisiones han
                                        sido obtenidas a partir de los factores de emisiones de MITERD.
                                    </li>
                                    <li><strong>Electricidad.</strong> Precio actual del gas obtenido del PVPC
                                        (Red
                                        Eléctrica). Precios a futuro calculados con modelo propio de Fundación
                                        Renovables. Las emisiones han sido obtenidas a partir de los factores de
                                        emisiones de Red Eléctrica.
                                    </li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="col-md-3"></div>
            </div>
        </div>
    );
}

export default Sources;