import React from "react";
import styles from "./Bar.module.css";
import {Tooltip} from "react-tooltip";
import getTooltip from "./utils/getTooltip";
import getSecciones from "./utils/getSecciones";

const Bar = ({id, width, sections, costs}) => {

    const secciones = getSecciones(sections);

    return (
        <div className={styles.costBar} style={{width: `${width}%`}}>
            <div className={styles.innerBar}>
                {Object.keys(secciones).map((key, index) => (
                    <>
                        <div data-tooltip-id={`${id}-${key}`} key={index}
                             style={{width: `${secciones[key].width}%`, backgroundColor: secciones[key].color}}>
                        </div>
                        <Tooltip id={`${id}-${key}`} place="bottom" type="dark" effect="solid" content={getTooltip(costs, key)}/>
                    </>
                ))}
            </div>
        </div>
    );


}

export default Bar;