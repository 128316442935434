import React from 'react';
import styles from './Intro.module.css';

const Intro = () => {
    return (
        <div className="row">
            <div className={`row ${styles.title}`}>
                <div className={`col-12`}>
                    <h1>Descubre las posibles soluciones</h1>
                </div>
            </div>
            <div className="row">
                <div className={"col-3"}></div>
                <div className={`col-6 ${styles.introduction1}`}>
                    Aquí tienes las cinco soluciones habituales para cubrir tus necesidades de calefacción, refrigeración
                    y agua caliente, con el fin de dotar de una temperatura idónea tu hogar durante todas la épocas
                    del año. Para ello, en algunas opciones es necesario un equipo auxiliar ya que el equipo
                    principal no cubre todas las necesidades.
                </div>
                <div className={"col-3"}></div>
            </div>
            <div className="row">
                <div className={"col-3"}></div>
                <div className={`col-6 ${styles.introduction2}`}>
                    Los cálculos incluyen el coste de los equipos, el mantenimiento y el coste de la energía que vas a
                    consumir en 20 años. También hemos calculado las emisiones de CO<sub>2</sub> que se producirán en esas dos décadas,
                    ya que es un dato que tenemos que tener en cuenta a la hora de elegir si queremos contribuir a la
                    lucha contra el cambio climático.
                </div>
                <div className={"col-3"}></div>
            </div>
        </div>
    );
}

export default Intro;