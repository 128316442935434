import React from "react";
import styles from "./NumberPeople.module.css";
import Persons from "./components/persons/Persons";

const NumberPeople = ({numberPeople, setNumberPeople}) => {

    return (
        <div>
            <div className={`${styles.pregunta}`}>
                4. Por último, el número de personas influye en la demando de agua caliente necesaria (ACS).
                <b>Dinos el número de personas que viven en tu vivienda</b>:
            </div>
            <Persons numberPeople={numberPeople} setNumberPeople={setNumberPeople} />
        </div>
    );
}

export default NumberPeople;