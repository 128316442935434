import React from "react";
import styles from './Autoconsumo.module.css';
import {Bombilla, Placa, SistemasElectricos} from './images'

const Autoconsumo = () => {
    return (
        <div className="row">
            <div className="col-md-3"></div>
            <div className={`col-md-6 ${styles.gradiente}`}>
                <div className={`row ${styles.rowSpacing}`}>
                    <div className={`d-flex col-md-7 ${styles.titleSection}`}>
                        <div className={styles.iconWrapper}>
                            <img src={Bombilla} alt="" width="80px"/>
                        </div>
                        <div>
                            Cualquiera de los sistemas eléctricos se puede combinar con autoconsumo
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img src={SistemasElectricos} alt=""/>
                    </div>
                </div>
                <div className={`row d-flex align-items-center ${styles.content}`}>
                    <div className="col-md-4">
                        <img src={Placa} alt=""/>
                    </div>
                    <div className="col-md-8">
                        <p>
                            Producir <b className={styles.highlight}>tu propia energía eléctrica</b> reduce el gasto económico
                            calculado anteriormente, pues nos garantiza un precio bajo y estable de la energía que utilizamos.
                        </p>
                        <p>
                            El coste de los paneles se amortiza en unos 5-7 años, después toda la electricidad que
                            consumamos procedente de ellos tendrá "coste cero". La vida útil de estos sistemas ronda los
                            25-30 años.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-3"></div>
        </div>
    )
}

export default Autoconsumo;
