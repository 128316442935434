import React from "react";
import Tooltip from "../components/tooltip/Tooltip";

function getTooltip(emissions, key) {
    if (key === "consumoCalefaccion") {
        return (
            <Tooltip descEmisionCompleto="Emisiones de consumo de calefacción"
                     descEmision1="Equipo principal"
                     emision1={thousandPointSeparator(emissions.emisiones[0].emisionesConsumoCalefaccion)} descEmision2="Equipo auxiliar"
                     emision2={thousandPointSeparator(emissions.emisiones[1].emisionesConsumoCalefaccion)}/>
        );
    }
    if (key === "consumoACS") {
        return (
            <Tooltip descEmisionCompleto="Emisiones de consumo de agua caliente (ACS)"
                     descEmision1="Equipo principal"
                     emision1={thousandPointSeparator(emissions.emisiones[0].emisionesConsumoACS)} descEmision2="Equipo auxiliar"
                     emision2={thousandPointSeparator(emissions.emisiones[1].emisionesConsumoACS)}/>
        );
    }
    if (key === "consumoRefrigeracion") {
        return (
            <Tooltip descEmisionCompleto="Emisiones de consumo de refrigeración"
                     descEmision1="Equipo principal"
                     emision1={thousandPointSeparator(emissions.emisiones[0].emisionesConsumoRefrigeracion)} descEmision2="Equipo auxiliar"
                     emision2={thousandPointSeparator(emissions.emisiones[1].emisionesConsumoRefrigeracion)}/>
        );
    }
    return null;
}

function thousandPointSeparator(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default getTooltip;