import React from "react";
import styles from "../../Bar.module.css"

const Tooltip = ({descCosteCompleto, descCoste1, coste1, descCoste2, coste2}) => {
    return (
        <div className={styles.tooltip}>
            <div className={styles.tooltipTitle}>{descCosteCompleto}€</div>
            <div> - {descCoste1}: {coste1}€</div>
            <div> - {descCoste2}: {coste2}€</div>
        </div>
    );
}

export default Tooltip;