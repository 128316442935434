import React from "react";
import System from "../system/System";
import styles from "./Compare.module.css";

const Compare = ({costs, percentages}) => {
    // Combina los datos de costs y percentages
    const combinedData = costs.map((cost, index) => ({
        system: cost,
        bar: {
            width: percentages.porcentajeTotalCadaBarra[index],
            sections: percentages.porcentajesEnCadaBarra[index]
        }
    }));

    // Ordena los datos combinados por porcentajeTotalCadaBarra
    combinedData.sort((a, b) => a.bar.width - b.bar.width);

    return (
        <div className={styles.subpanel}>
            {combinedData.map((data, index) => (
                <System key={index} system={data.system} bar={data.bar} />
            ))}
            <div className="row">
                <div className="col-1"></div>
                <div className="col-2">
                    <div className="row">
                        <div className={`col-2 ${styles.color1}`}></div>
                        <div className={`col-10 ${styles.nota}`}>Costes de inversión y mantenimienton del equipo</div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="row">
                        <div className={`col-2 ${styles.color2}`}></div>
                        <div className={`col-10 ${styles.nota}`}>Costes de inversión y mantenimiento del equipo auxiliar</div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="row">
                        <div className={`col-2 ${styles.color3}`}></div>
                        <div className={`col-10 ${styles.nota}`}>Costes de consumo calefacción</div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="row">
                        <div className={`col-2 ${styles.color4}`}></div>
                        <div className={`col-10 ${styles.nota}`}>Costes de consumo agua caliente (ACS)</div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="row">
                        <div className={`col-2 ${styles.color5}`}></div>
                        <div className={`col-10 ${styles.nota}`}>Costes de consumo refrigeración</div>
                    </div>
                </div>
                <div className="col-1"></div>
            </div>
        </div>
    );
}

export default Compare;