import React from "react";
import styles from "./Climate.module.css";
import {opcionA, opcionB, opcionC, atlantico, continental, mediterraneo} from "./images";
import Opcion from "./components/opcion/Opcion";

const Climate = ({climate, setClimate}) => {

    const imagen = {
        "Atlantico": atlantico,
        "Continental": continental,
        "Mediterraneo": mediterraneo
    }

    return (
        <div className="row">
            <div className={styles.pregunta}>
                1. Para calcular tus necesidades energéticas necesitamos saber <b>cómo es el clima de tu
                municipio/región.</b> Elige una de estas opciones:
            </div>
            <div className={`row ${styles.opciones}`}>
                <div className="col-md-1"></div>
                <div className={`col-md-7 ${styles.formulario}`}>
                    <Opcion
                        seleccionado={climate === "Atlantico"}
                        updateClima={() => setClimate("Atlantico")}
                        imagen={opcionA}
                        nombre="Atlántico (Inviernos frescos y veranos moderados)"
                    />
                    <Opcion
                        seleccionado={climate === "Continental"}
                        updateClima={() => setClimate("Continental")}
                        imagen={opcionB}
                        nombre="Continental (Inviernos fríos y veranos calurosos)"
                    />
                    <Opcion
                        seleccionado={climate === "Mediterraneo"}
                        updateClima={() => setClimate("Mediterraneo")}
                        imagen={opcionC}
                        nombre="Mediterráneo (Inviernos moderados y veranos muy calurosos)"
                    />
                </div>
                <div className={`col-md-4 align-content-center justify-content-center`}>
                    <img id="climate-image" src={imagen[climate]} alt="" width="100%" className={styles.mapa}/>
                </div>
            </div>
        </div>
    );
}

export default Climate;