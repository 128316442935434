import React from 'react';
import styles from './Contexto.module.css';
import Resumen from './components/resumen/Resumen';
import {
    getClimateImage,
    getHouseSurfaceImage,
    getHouseTypeImage,
    getNumberPeopleImage,
    getClimateText,
    getHouseTypeText,
    getHouseSurface,
    getNumberPeople
} from './components/resumen/Data';

const Contexto = ({climate, houseType, houseSurface, numberPeople}) => {


    return (
        <div className={styles.contexto}>
            <div className="row">
                <div className="col-3"></div>
                <div className={`col-6 ${styles.title}`}>RESULTADOS PARA:</div>
                <div className="col-3"></div>
            </div>
            <div className="row">
                <div className="col-3"></div>
                <div className={`col-6 ${styles.module}`}>
                    <div className="row">
                        <div className="col-3">
                            <Resumen image={getClimateImage(climate)} text={getClimateText(climate)} />
                        </div>
                        <div className="col-3">
                            <Resumen image={getHouseTypeImage(houseType)} text={getHouseTypeText(houseType)} />
                        </div>
                        <div className="col-3">
                            <Resumen image={getHouseSurfaceImage(houseSurface)} text={getHouseSurface(houseSurface)} />
                        </div>
                        <div className="col-3">
                            <Resumen image={getNumberPeopleImage()} text={getNumberPeople(numberPeople)} />
                        </div>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
    );
};

export default Contexto;