import React from "react";
import Autoconsumo from "./components/autoconsumo/Autoconsumo";
import styles from "./ZeroPage.module.css";
import {useNavigate, useLocation} from "react-router-dom";
import Systems from "./components/systems/Systems";
import VolverComparador from './images'

const ZeroPage = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const {climate, houseType, houseSurface, numberPeople} = location.state || {};

    return (
        <>
            <div className={styles.title}>Sistemas con cero emisiones</div>
            <Autoconsumo/>
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                    <Systems/>
                </div>
                <div className="col-3"></div>
            </div>

            

            <div className={`row ${styles.spacing}`}>
                <div className="col-3"></div>
                <div className="col-6">
                    <div>
                        <input type="image" src={VolverComparador} alt="Volver al comparador"
                               width="305" height="42" onClick={() => navigate('/compare', {
                            state: {
                                climate,
                                houseType,
                                houseSurface,
                                numberPeople
                            }
                        })}/>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </>
    );
}

export default ZeroPage;