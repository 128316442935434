import React from 'react';
import Subpanel from "./components/subpanel/Subpanel";

const PanelSistemas = ({systems, moreInfo}) => {

    function getElementos(system) {
        return system.filter(sistema =>
            sistema.sistemaInfoName === 'sistema-info-aerotermia-aire-aire' ||
            sistema.sistemaInfoName === 'sistema-info-aerotermia-aire-agua');
    }

    return (
        <Subpanel systems={getElementos(systems[0].elementos)} moreInfo={moreInfo}/>
    );
}

export default PanelSistemas;