import React, {useState} from 'react';
import styles from './WelcomePage.module.css';
import backgroundImage from './images/background.png';
import knowBetterOptionImage from './images/conoce-mejor-opcion.png';
import {Objective, Systems} from './components';
import {useNavigate} from "react-router-dom";

const WelcomePage = () => {

    const navigate = useNavigate();

    const [calculate, setCalculate] = useState(true);

    return (
        <div className={styles.background} style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className="row">
                <div className="col-md-12">
                    <div className="row" style={{paddingBottom: '30px'}}>
                        <div className="col-md-3"></div>
                        <div className={`col-md-6`}>
                            <Objective/>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </div>

            <div id="seccion-sistemas" className={`row`}>
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className={styles.informacionTitle}>Estos son los que vamos a comparar</div>
                    <div className={styles.informacionSubtitle}>
                        Si ya los conoces accede directamente al comparador. Si no, aquí tienes la información más
                        importante que necesitas saber.
                    </div>
                </div>
                <div className="col-md-3"></div>
            </div>

            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <Systems calculate={setCalculate}/>
                </div>
                <div className="col-md-3"></div>
            </div>

            {calculate && (
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 d-flex justify-content-center">
                        <input type="image" src={knowBetterOptionImage} alt="Demanda"
                               width="301" height="54" onClick={() => navigate('/demand')}/>
                    </div>
                    <div className="col-md-3"></div>
                </div>
            )}
        </div>
    );
};

export default WelcomePage;
