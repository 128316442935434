import React from "react";
import Bar from "../bar/Bar";
import {getImagenSistema} from "./utils/imagesProvider";
import getDescription from "./utils/descriptionProvider";
import styles from "./System.module.css";
import { v4 as uuidv4 } from 'uuid';

const System = ({system, bar}) => {

    const sistema = system.sistema;

    return (
        <div>
            <div className={`row ${styles.system}`}>
                <div className="col-2"><img alt={`sistema`} src={getImagenSistema(sistema)}/></div>
                <div className="col-10">
                    <div className="row">
                        <div
                            className={`col-12 ${styles.description}`}>{getDescription(sistema)}</div>
                        <div className="col-12">
                            {bar && (<Bar id={uuidv4()} width={bar.width} sections={bar.sections} emissions={system}/>)}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default System;