import React from "react";

const Seccion = ({children}) => {
    return (
      <>
          {children}
      </>
    );
}

export default Seccion;