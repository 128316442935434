function getSecciones(sections) {
    return {
        inversionMantenimientoEquipoPrincipal: {
            width: sections.porcentajeInversionEquipoPrincipal + sections.porcentajeMantenimientoEquipoPrincipal,
            color: "#cbc04a"
        },
        inversionMantenimientoEquipoSecundario: {
            width: sections.porcentajeInversionEquipoSecundario + sections.porcentajeMantenimientoEquipoSecundario,
            color: "#dfde7c"
        },
        consumoCalefaccion: {
            width: sections.porcentajeConsumoCalefaccion,
            color: "#ac5372"
        },
        consumoACS: {
            width: sections.porcentajeConsumoACS,
            color: "#77bf95"
        },
        consumoRefrigeracion: {
            width: sections.porcentajeConsumoRefrigeracion,
            color: "#4a96cd"
        }
    };
}

export default getSecciones;