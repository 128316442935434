import React from "react";
import styles from "./Costes.module.css";
import Compare from "./components/compare/Compare";

const Costes = ({costes, porcentajes}) => {
    return (
        <div>
            <div className={styles.title}>SEGÚN SUS COSTES</div>
            {porcentajes && (<Compare costs={costes} percentages={porcentajes}/>)}
        </div>
    );
}

export default Costes;