import React from 'react';
import styles from './Footer.module.css'; // Importar los estilos desde Footer.module.css

import youtubeIcon from './images/redes/youtube.png';
import twitterIcon from './images/redes/twitter.png';
import linkedinIcon from './images/redes/linkedin.png';
import facebookIcon from './images/redes/facebook.png';
import instagramIcon from './images/redes/instagram.png';

import logoFR from './images/Logo_FR_Pos-web.png';
import logoExD from './images/Logo_ExD_Fondo_Azul.png';

import locationIcon from './images/contacto/localizacion.png';
import phoneIcon from './images/contacto/telefono.png';
import emailIcon from './images/contacto/email.png';

import greenGeeksIcon from './images/Green_11.png'; // Imagen externa

const Footer = () => {
    return (
        <div className="row" style={{ backgroundColor: 'black', color: 'white' }}>
            <div className="col-md-3"></div>
            <div className="col-md-6">
                <div className="row">
                    <div
                        className="col-md-12 d-flex justify-content-center align-items-center"
                        style={{ textAlign: 'center', flexDirection: 'row', margin: '20px 0 30px 0' }}
                    >
                        {[
                            { href: 'https://www.youtube.com/user/FRenovables', img: youtubeIcon },
                            { href: 'https://twitter.com/FRenovables', img: twitterIcon },
                            { href: 'https://www.linkedin.com/company/fundaci-n-renovables/', img: linkedinIcon },
                            { href: 'https://www.facebook.com/fundacionrenovables', img: facebookIcon },
                            { href: 'https://www.instagram.com/fundacionrenovables', img: instagramIcon },
                        ].map((item, index) => (
                            <div className="mx-1" key={index}>
                                <a href={item.href} target="_blank" rel="noopener noreferrer">
                                    <img src={item.img} className="img-fluid" style={{ width: '35px' }} alt="" />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 d-flex justify-content-center align-items-center" style={{ flexDirection: 'column' }}>
                        <div>
                            <a href="https://fundacionrenovables.org/" target="_blank" rel="noopener noreferrer">
                                <img src={logoFR} alt="Logo FR" />
                            </a>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <img src={logoExD} style={{ width: '100px' }} alt="Logo ExD" />
                        </div>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center align-items-start" style={{ flexDirection: 'column', paddingLeft: '50px' }}>
                        {[
                            { href: 'https://fundacionrenovables.org/', text: 'Inicio' },
                            { href: 'https://fundacionrenovables.org/la-fundacion/', text: 'La Fundación' },
                            { href: 'https://fundacionrenovables.org/que-hacemos/', text: 'Qué hacemos' },
                            { href: 'https://fundacionrenovables.org/comunicacion/?post_types=notas', text: 'Comunicación' },
                            { href: 'https://fundacionrenovables.org/contacto/', text: 'Contacto' },
                            { href: 'https://fundacionrenovables.org/hazte-amiga-o-amigo/', text: 'Únete' },
                        ].map((item, index) => (
                            <a href={item.href} target="_blank" rel="noopener noreferrer" className={styles.enlaceFooter} key={index}>
                                {item.text}
                            </a>
                        ))}
                    </div>
                    <div className="col-md-6 d-flex justify-content-center align-items-start" style={{ flexDirection: 'column', paddingLeft: '50px' }}>
                        <div>
                            <img src={locationIcon} style={{ padding: '0 6px 0 0' }} alt="Localización" />
                            C/ Santa Engracia, 108. 5º Interior. Izda. 28003
                        </div>
                        <div>
                            <img src={phoneIcon} style={{ padding: '0 6px 0 1px' }} alt="Teléfono" />
                            +34 625 47 42 11
                        </div>
                        <div>
                            <img src={emailIcon} style={{ padding: '0 6px 0 2px' }} alt="Email" />
                            <a href="mailto:fundacion@fundacionrenovables.org" className={styles.enlaceFooter}>
                                fundacion@fundacionrenovables.org
                            </a>
                        </div>
                        <div>
                            <img src={emailIcon} style={{ padding: '0 6px 0 2px' }} alt="Email" />
                            <a href="mailto:comunicacion@fundacionrenovables.org" className={styles.enlaceFooter}>
                                comunicacion@fundacionrenovables.org
                            </a>
                        </div>
                        <div className={`row ${styles.compensacion}`}>
                            <div className="col-md-3">
                                <a href="https://www.greengeeks.com/track/u145705" target="_blank" rel="noopener noreferrer">
                                    <img src={greenGeeksIcon} alt="Green Geeks" width="86" height="92" />
                                </a>
                            </div>
                            <div className="col-md-8" style={{ padding: '20px 0 0 20px' }}>
                                Compensamos la huella de carbono en un 300%. Web 100% impulsada por energías renovables.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <span className={styles.divisor}> </span>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center" style={{ flexDirection: 'row', margin: '5px 0 10px 0' }}>
                        <div>
                            <a href="https://fundacionrenovables.org/politica-privacidad-aviso-legal/" target="_blank" rel="noopener noreferrer" className={styles.enlaceCreditos}>
                                Aviso Legal y Política de Privacidad
                            </a>
                        </div>
                        <div>
                            <a href="https://fundacionrenovables.org/la-fundacion/transparencia/" target="_blank" rel="noopener noreferrer" className={styles.enlaceCreditos}>
                                Transparencia
                            </a>
                        </div>
                        <div>
                            <a href="mailto:fundacion@fundacionrenovables.org" target="_blank" rel="noopener noreferrer" className={styles.enlaceCreditos} style={{ color: 'orange' }}>
                                Diseño App: Fundación Renovables (Servicio TI)
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3"></div>
        </div>
    );
};

export default Footer;
