import React from 'react';
import styles from './Header.module.css';
import logoImage from './images/Logo_FR_Pos-web.png';
import youtubeImage from './images/redes/youtube.png';
import twitterImage from './images/redes/twitter.png';
import linkedinImage from './images/redes/linkedin.png';
import facebookImage from './images/redes/facebook.png';
import instagramImage from './images/redes/instagram.png';

const Header = () => {
    return (
        <div className={`row ${styles.topSpace}`}>
            <div className="col-md-3"></div>
            <div className="col-md-6">
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-3">
                        <a href="https://fundacionrenovables.org/" target="_blank" rel="noopener noreferrer">
                            <img src={logoImage} alt="Fundación Renovables Logo" />
                        </a>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-7 d-flex align-items-center justify-items-center">
                        <div className="row">
                            <div className="col-md-12 my-2">
                                <div className="row">
                                    <div className="col-md-6"></div>
                                    <div className={`col-md-5 d-flex ${styles.enFila}`}>
                                        <div className="mx-1">
                                            <a href="https://www.youtube.com/user/FRenovables" target="_blank" rel="noopener noreferrer">
                                                <img src={youtubeImage} className="img-fluid" style={{ width: '35px' }} alt="YouTube" />
                                            </a>
                                        </div>
                                        <div className="mx-1">
                                            <a href="https://twitter.com/FRenovables" target="_blank" rel="noopener noreferrer">
                                                <img src={twitterImage} className={`img-fluid ${styles.w35}`} alt="Twitter" />
                                            </a>
                                        </div>
                                        <div className="mx-1">
                                            <a href="https://www.linkedin.com/company/fundaci-n-renovables/" target="_blank" rel="noopener noreferrer">
                                                <img src={linkedinImage} className={`img-fluid ${styles.w35}`} alt="LinkedIn" />
                                            </a>
                                        </div>
                                        <div className="mx-1">
                                            <a href="https://www.facebook.com/fundacionrenovables" target="_blank" rel="noopener noreferrer">
                                                <img src={facebookImage} className={`img-fluid ${styles.w35}`} alt="Facebook" />
                                            </a>
                                        </div>
                                        <div className="mx-1">
                                            <a href="https://www.instagram.com/fundacionrenovables" target="_blank" rel="noopener noreferrer">
                                                <img src={instagramImage} className={`img-fluid ${styles.w35}`} alt="Instagram" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                            </div>
                            <div className={`col-md-12 my-2`}>
                                <a href="https://fundacionrenovables.org/la-fundacion/objetivos-ideario/" target="_blank" rel="noopener noreferrer" className={`mx-2 ${styles.enlaceHeader}`}>
                                    ⬞ La Fundación
                                </a>
                                <a href="https://fundacionrenovables.org/que-hacemos/" target="_blank" rel="noopener noreferrer" className={`mx-2 ${styles.enlaceHeader}`}>
                                    ⬞ Qué hacemos
                                </a>
                                <a href="https://fundacionrenovables.org/comunicacion/filter/plain_query/post_type:notas/" target="_blank" rel="noopener noreferrer" className={`mx-2 ${styles.enlaceHeader}`}>
                                    ⬞ Comunicación
                                </a>
                                <a href="https://fundacionrenovables.org/contacto/" target="_blank" rel="noopener noreferrer" className={`mx-2 ${styles.enlaceHeader}`}>
                                    ⬞ Contacto
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3"></div>
        </div>
    );
};

export default Header;