import React from "react";
import styles from "./Persons.module.css";

import {seleccionado, noSeleccionado} from "./images"

const Persons = ({numberPeople, setNumberPeople}) => {
    return (
        <div className="row">
            <div className="col-1"></div>
            <div className="col-10">
                <div className={styles.monigotes}>
                    <div className={styles.flexContainer}>
                        {[...Array(10)].map((_, i) => (
                            <div className={styles.flexItem} key={i} onClick={() => setNumberPeople(i + 1)}>
                                <img id={`img-${i + 1}`}
                                     src={numberPeople <= i ? seleccionado : noSeleccionado}
                                     alt=""/>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio"
                                           name="numero_habitantes_casa"
                                           id={`numero_habitantes_casa_${i + 1}`} value={i + 1}
                                           style={{display: 'none'}} checked={i + 1 === 1}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="col-1"></div>
        </div>
    );
}

export default Persons;