import {
    bombaCalorAireAgua,
    bombaCalorAireAireTermoElectrico,
    calderaGasoleoAireAcondicionado,
    calderaGasAireAcondicionado,
    calderaGasCondensacionAireAcondicionado,
    incluyeACS,
    incluyeCalor,
    incluyeFrio
} from "../images"

function getImagenSistema(system) {
    if (system.sistemasIncluidos[0].nombre === "Caldera de gasóleo" && system.sistemasIncluidos[1].nombre === "Aire acondicionado") {
        return calderaGasoleoAireAcondicionado;
    }
    if (system.sistemasIncluidos[0].nombre === "Caldera de gas" && system.sistemasIncluidos[1].nombre === "Aire acondicionado") {
        return calderaGasAireAcondicionado;
    }

    if (system.sistemasIncluidos[0].nombre === "Caldera de gas de condensación" && system.sistemasIncluidos[1].nombre === "Aire acondicionado") {
        return calderaGasCondensacionAireAcondicionado;
    }

    if (system.sistemasIncluidos[0].nombre === "Bomba de calor aire-aire" && system.sistemasIncluidos[1].nombre === "Termo eléctrico") {
        return bombaCalorAireAireTermoElectrico;
    }

    if (system.sistemasIncluidos[0].nombre === "Bomba de calor aire-agua") {
        return bombaCalorAireAgua;
    }
}

function getImagenServicio(service) {
    if (service === "acs") {
        return incluyeACS;
    }
    if (service === "calor") {
        return incluyeCalor;
    }
    if (service === "frio") {
        return incluyeFrio;
    }
}

export {getImagenSistema};

export {getImagenServicio};