import React from 'react';
import styles from './Resumen.module.css';

const Resumen = ({image, text}) => {
    return (
        <div className={`row ${styles.contenido}`}>
            <div className={`col-4`}>
                <img src={image} alt="icon" width={`100%`}/>
            </div>
            <div className={`col-8 ${styles.contenido}`}>
                {text}
            </div>
        </div>
    );
}

export default Resumen;