import React from "react";
import styles from "./Sistema.module.css";
import {incluyeCalor, incluyeFrio, incluyeACS, atras} from "./images";
import {
    calderaGas,
    calderaGasoil,
    calderaGasCondensacion,
    aerotermiaAireAire,
    aerotermiaAireAgua,
    aireAcondicionado,
    termoElectrico
} from "../../../../images";

const systemImageMap = new Map([
    ["sistema-info-caldera-gasoleo", calderaGas],
    ["sistema-info-caldera-gas", calderaGasoil],
    ["sistema-info-caldera-gas-condensacion", calderaGasCondensacion],
    ["sistema-info-aerotermia-aire-aire", aerotermiaAireAire],
    ["sistema-info-aerotermia-aire-agua", aerotermiaAireAgua],
    ["sistema-info-aire-acondicionado", aireAcondicionado],
    ["sistema-info-termo-electrico", termoElectrico]
]);

const Sistema = ({system, moreInfo}) => {

    return (
        <>
            <div className="row">
                <div className={`row ${styles.sistemaTitulo}`}><h3>{system.titulo}</h3></div>
            </div>
            <div className="row">
                <div className="col-1"></div>
                <div
                    className={`col-2 d-flex flex-column align-items-center justify-content-center ${styles.sistema}`}>
                    <img src={systemImageMap.get(system.sistemaInfoName)} alt={system.titulo}/>
                </div>
                <div className={`col-9 d-flex flex-column justify-content-end`}>
                    <div className="row">
                        <div className={`col-3 ${styles.sistemaApartadoTitulo}`}>Fuente</div>
                        <div className="col-8" style={{textAlign: "left"}}>{system.fuente}</div>
                    </div>
                    <div className="row">
                        <div className={`col-3 ${styles.sistemaApartadoTitulo}`}>Usos</div>
                        <div className={`col-8 ${styles.usos}`}>
                            {system.usos.calefaccion && (
                                <>
                                    <img alt={`incluye calor`} src={incluyeCalor} width="29px" height="29px"></img>
                                    <div>Calefacción</div>
                                </>
                            )}
                            {system.usos.refrigeracion && (
                                <>
                                    <img alt={`incluye frio`} src={incluyeFrio} width="29px" height="29px"></img>
                                    <div>Refrigeración</div>
                                </>
                            )}
                            {system.usos.acs && (
                                <>
                                    <img alt={`incluye agua caliente sanitaria`} src={incluyeACS} width="29px" height="29px"></img>
                                    <div>ACS</div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-3 ${styles.sistemaApartadoTitulo}`}>Funcionamiento
                        </div>
                        <div className="col-8" style={{textAlign: "left"}}>{system.comoFunciona}</div>
                    </div>
                </div>
            </div>
            <div className={`row ${styles.atras}`}>
                <div className="col-9"></div>
                <div className="col-2">
                    <input type="image" src={atras} alt="Demanda"
                           width="148" height="53" onClick={() => moreInfo("")}/>
                </div>
                <div className="col-1"></div>
            </div>
        </>
    )
        ;
}

export default Sistema;