import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import Intro from "./components/intro/Intro";
import Costes from "./components/costes/Costes";
import Emisiones from "./components/emisiones/Emisiones";
import styles from './ComparePage.module.css';
import {sistemaCeroEmisiones, fuentesUtilizadas, atras} from './images'
import {useNavigate} from "react-router-dom";
import Sources from "./components/sources/Sources";
import {HashLink as Link} from 'react-router-hash-link';
import Contexto  from "./components/contexto/Contexto";

const ComparePage = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const {climate, houseType, houseSurface, numberPeople} = location.state || {};

    const [costes, setCostes] = useState('');
    const [emisiones, setEmisiones] = useState('');
    const [porcentajesCostes, setPorcentajesCostes] = useState('');
    const [porcentajeEmisiones, setPorcentajeEmisiones] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fuentes, setFuentes] = useState(false);

    useEffect(() => {
        // Realiza la primera llamada para obtener los costes y emisiones
        const fetchData = async () => {
            try {
                const response = await axios.post('https://comparador.fundacionrenovables.org/laravel/public/api/calculate', {
                    climate,
                    houseType,
                    houseSurface,
                    numberPeople
                });

                setCostes(response.data.costes);
                setEmisiones(response.data.emisiones);

                // Realiza las llamadas para porcentajes en paralelo
                const [costesResponse, emisionesResponse] = await Promise.all([
                    axios.post('https://comparador.fundacionrenovables.org/laravel/public/api/compare/costs', {
                        costes: response.data.costes
                    }),
                    axios.post('https://comparador.fundacionrenovables.org/laravel/public/api/compare/emissions', {
                        emisiones: response.data.emisiones
                    })
                ]);

                setPorcentajesCostes(costesResponse.data);
                setPorcentajeEmisiones(emisionesResponse.data);
                setLoading(false)

            } catch (err) {
                setError("Error: " + err.message);
                setLoading(false)
            }
        };

        fetchData();
    }, [climate, houseType, houseSurface, numberPeople]);

    return (
        <div>
            <Intro/>
            {
                loading && (<p>Cargando datos...</p>)
            }
            <Contexto climate={climate} houseType={houseType} houseSurface={houseSurface} numberPeople={numberPeople}/>
            {
                !loading && !fuentes && (
                    <div id="seccion-comparacion">
                        <div className="row">
                            <div className="col-3"></div>
                            <div className="col-6">
                                {porcentajesCostes ? (
                                    <Costes costes={costes} porcentajes={porcentajesCostes}/>
                                ) : (
                                    error && <p>{error}</p>
                                )}
                            </div>
                            <div className="col-3"></div>
                        </div>
                        <div className="row">
                            <div className="col-3"></div>
                            <div className="col-6">
                                {porcentajeEmisiones ? (
                                    <Emisiones emisiones={emisiones} porcentajes={porcentajeEmisiones}/>
                                ) : (
                                    error && <p>{error}</p>
                                )}
                            </div>
                            <div className="col-3"></div>
                        </div>
                    </div>
                )
            }
            {
                !loading && fuentes && (
                    <Sources/>
                )
            }

            {/* Ver fuentes */}
            {
                (!fuentes) && (
                    <Link smooth to={"#seccion-fuentes"} state={{ climate, houseType, houseSurface, numberPeople }}>
                        <input type="image" src={fuentesUtilizadas} alt="Fuentes utilizadas" style={{marginTop: "20px"}}
                               width="285" height="43" onClick={() => setFuentes(true)}/>
                    </Link>
                )
            }
            {/* Atrás */}
            {

                (fuentes) && (
                    <Link smooth to={"#seccion-comparacion"} state={{ climate, houseType, houseSurface, numberPeople }}>
                        <input type="image" src={atras} alt="Atras" style={{marginTop: "40px"}}
                               width="148" height="43" onClick={() => setFuentes(false)}/>
                    </Link>
                )
            }

            <div className={`row ${styles.botones}`}>
                <div className="col-3"></div>
                <div className="col-6">
                    <input type="image" src={sistemaCeroEmisiones} alt="Cero Emisiones"
                           width="440" height="43" onClick={() => navigate('/zero', {
                        state: {
                            climate,
                            houseType,
                            houseSurface,
                            numberPeople
                        }
                    })}/>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
    )
        ;
}

export default ComparePage;