import React from "react";
import styles from "./Opcion.module.css";

const Opcion = ({seleccionado, updateClima, imagen, nombre}) => {

    function imagenFondo(seleccionado) {
        return seleccionado ? styles.opcionSeleccionada : styles.opcionNoSeleccionada;
    }

    return (
        <div className={`row ${styles.opcion} ${imagenFondo(seleccionado)}`} onClick={() => updateClima()}>
            <div className={`col-md-1`}>
                <input className="form-check-input" type="radio" name="clima"
                       style={{display: 'none'}}/>
                <img src={imagen} alt=""/>
            </div>
            <div className={`col-md-11 ${styles.descripcion}`}>
                {nombre}
            </div>
        </div>
    );
}

export default Opcion;