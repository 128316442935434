import React from "react";
import styles from "./HouseSurface.module.css";
import {menos90, mas90, opcionA, opcionB} from './images';
import Opcion from "./components/opcion/Opcion";

const HouseSurface = ({houseSurface, setHouseSurface}) => {

    return (
        <div>
            <div className={styles.pregunta}>
                3. El tamaño de la vivienda también influye en la cantidad de energía que necesitamos para
                calentarla o refrigerarla. Elige una de estas opciones según <b>la superficie de tu vivienda</b>:
            </div>
            <div className="row" style={{marginBottom: '80px'}}>
                <div className="col-md-1"></div>
                <div className={`col-md-7 flex-column ${styles.formulario}`}>
                    <Opcion
                        seleccionado={opcionA}
                        value="Menos de 90 metros cuadrados"
                        checked={houseSurface === "Menor90"}
                        houseSurface={() => setHouseSurface("Menor90")}
                    />
                    <Opcion
                        seleccionado={opcionB}
                        value="Más de 90 metros cuadrados"
                        checked={houseSurface === "Mayor90"}
                        houseSurface={() => setHouseSurface("Mayor90")}
                    />
                </div>
                <div className="col-md-4 tierra">
                    <img id="superficie-image" src={(houseSurface === "Menor90") ? menos90 : mas90} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default HouseSurface;