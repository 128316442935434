import React from "react";
import Tooltip from "../components/tooltip/Tooltip";

function getTooltip(costs, key) {
    if (key === "inversionMantenimientoEquipoPrincipal") {
        return (
            <Tooltip descCosteCompleto={`Inversión y mantenimiento del equipo principal: ${thousandPointSeparator(costs.costes[0].costesInversion + costs.costes[0].costesMantenimiento)}`}
                     descCoste1="Inversión"
                     coste1={thousandPointSeparator(costs.costes[0].costesInversion)} descCoste2="Mantenimiento"
                     coste2={thousandPointSeparator(costs.costes[0].costesMantenimiento)}/>
        );
    }
    if (key === "inversionMantenimientoEquipoSecundario") {
        return (
            <Tooltip descCosteCompleto={`Inversión y mantenimiento del equipo auxiliar: ${thousandPointSeparator(costs.costes[1].costesInversion + costs.costes[1].costesMantenimiento)}`}
                     descCoste1="Inversión"
                     coste1={thousandPointSeparator(costs.costes[1].costesInversion)} descCoste2="Mantenimiento"
                     coste2={thousandPointSeparator(costs.costes[1].costesMantenimiento)}/>
        );
    }
    if (key === "consumoCalefaccion") {
        return (
            <Tooltip descCosteCompleto={`Consumo de calefacción: ${thousandPointSeparator(costs.costes[0].costesConsumoCalefaccion + costs.costes[1].costesConsumoCalefaccion)}`}
                     descCoste1="Equipo principal"
                     coste1={thousandPointSeparator(costs.costes[0].costesConsumoCalefaccion)} descCoste2="Equipo auxiliar"
                     coste2={thousandPointSeparator(costs.costes[1].costesConsumoCalefaccion)}/>
        );
    }
    if (key === "consumoACS") {
        return (
            <Tooltip descCosteCompleto={`Consumo de agua caliente (ACS): ${thousandPointSeparator(costs.costes[0].costesConsumoACS + costs.costes[1].costesConsumoACS)}`}
                     descCoste1="Equipo principal"
                     coste1={thousandPointSeparator(costs.costes[0].costesConsumoACS)} descCoste2="Equipo auxiliar"
                     coste2={thousandPointSeparator(costs.costes[1].costesConsumoACS)}/>
        );
    }
    if (key === "consumoRefrigeracion") {
        return (
            <Tooltip descCosteCompleto={`Consumo de refrigeración: ${thousandPointSeparator(costs.costes[0].costesConsumoRefrigeracion + costs.costes[1].costesConsumoRefrigeracion)}`}
                     descCoste1="Equipo principal"
                     coste1={thousandPointSeparator(costs.costes[0].costesConsumoRefrigeracion)} descCoste2="Equipo auxiliar"
                     coste2={thousandPointSeparator(costs.costes[1].costesConsumoRefrigeracion)}/>
        );
    }
    return null;
}

function thousandPointSeparator(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default getTooltip;