import React, { useState, useEffect } from 'react';

import styles from "./DemandPage.module.css";
import {background, calcular} from './images'
import { Climate, HouseType, HouseSurface, NumberPeople } from "./components";
import { useNavigate } from "react-router-dom";

const DemandPage = () => {
    const navigate = useNavigate();

    const [climate, setClimate] = useState("Atlantico");
    const [houseType, setHouseType] = useState("Multifamiliar");
    const [houseSurface, setHouseSurface] = useState("Menor90");
    const [numberPeople, setNumberPeople] = useState(4);

    const handleSubmit = async () => {
        navigate('/compare', {
            state: {
                climate,
                houseType,
                houseSurface,
                numberPeople
            }
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={`row ${styles.background}`} style={{backgroundImage: `url(${background})`}}>
            <div className="col-md-3">
            </div>
            <div className={`col-md-6 ${styles.demandaFondoTransparente}`}>
                <div className="row">
                    <div className={styles.demandaTitle}><h1>Antes de comparar los sistemas</h1></div>
                    <div className={styles.demandaSubtitle}>Te vamos a hacer cuatro preguntas sencillas que nos ayudarán
                        a identificar cuáles son tus necesidades específicas.
                    </div>
                </div>
                <div className="row">
                    <Climate climate={climate} setClimate={setClimate} />
                    <HouseType houseType={houseType} setHouseType={setHouseType} />
                    <HouseSurface houseSurface={houseSurface} setHouseSurface={setHouseSurface} />
                    <NumberPeople numberPeople={numberPeople} setNumberPeople={setNumberPeople} />
                    <div className={`row ${styles.siguiente}`}>
                        <div className="d-flex justify-content-center">
                            <input id="siguiente" type="image"
                                   src={calcular} alt="Calcular"
                                   width="218" height="53"
                                   onClick={handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3"></div>
        </div>
    );
}

export default DemandPage;