import React from "react";
import styles from "../../Bar.module.css"

const Tooltip = ({descEmisionCompleto, descEmision1, emision1, descEmision2, emision2}) => {
    return (
        <div className={styles.tooltip}>
            <div className={styles.tooltipTitle}>{descEmisionCompleto}:</div>
            <div> - {descEmision1}: {emision1}€</div>
            <div> - {descEmision2}: {emision2}€</div>
        </div>
    );
}

export default Tooltip;