import React from 'react';
import Header from "../header/Header";
import Footer from "../footer/Footer";

const Layout = ({ children }) => {
    return (
        <div>
            <div className="App">
                <Header/>
                {children}
                <Footer/>
            </div>
        </div>
    );
};

export default Layout;