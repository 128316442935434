import React from 'react';
import Panel from "../../../../../../components/panel/Panel";
import Subpanel from "./components/subpanel/Subpanel";
import styles from "./PanelSistemas.module.css";

const PanelSistemas = ({systems, moreInfo}) => {
    const menu = {
        "title1": systems[0].nombre,
        "title2": systems[1].nombre,
        "title3": systems[2].nombre,
        "title4": systems[3].nombre
    };

    return (
        <>
            <Panel
                menu={menu}
                panel={<Subpanel systems={systems[0].elementos} moreInfo={moreInfo}/>}
                subpanel1={<Subpanel systems={systems[1].elementos} moreInfo={moreInfo}/>}
                subpanel2={<Subpanel systems={systems[2].elementos} moreInfo={moreInfo}/>}
                subpanel3={<Subpanel systems={systems[3].elementos} moreInfo={moreInfo}/>}
                styles={styles}
            />
        </>
    );
}

export default PanelSistemas;