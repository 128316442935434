import {getImagenServicio} from "./imagesProvider";

function getDescription(system) {
    let system1 = system.sistemasIncluidos[0];
    let system2 = system.sistemasIncluidos[1];

    if (system1.nombre === "Bomba de calor aire-agua") {
        return getSingleDescription(system1);
    } else {
        return (
            <>
                {getSingleDescription(system1)}
                {` + `}
                {getSingleDescription(system2)}
            </>
        );
    }
}

function getSingleDescription(system) {
    let nombre = system.nombre;
    let incluyeACS = system.incluyeACS ?
        <> {` `} <img alt={`incluye agua caliente sanitaria`} src={getImagenServicio("acs")}/></> : null;
    let incluyeCalor = system.incluyeCalor ?
        <> {` `} <img alt={`incluye calor`} src={getImagenServicio("calor")}/></>: null;
    let incluyeFrio = system.incluyeFrio ?
        <>  {` `} <img alt={`incluye frio`} src={getImagenServicio("frio")}/></> : null;

    return (
        <>
            {nombre}
            {incluyeACS}
            {incluyeCalor}
            {incluyeFrio}
        </>
    );
}

export default getDescription;