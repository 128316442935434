import React from "react";
import styles from "./Sistema.module.css";
import {
    calderaGas,
    calderaGasoil,
    calderaGasCondensacion,
    aerotermiaAireAire,
    aerotermiaAireAgua,
    aireAcondicionado,
    termoElectrico
} from "../../../../images";

const systemImageMap = new Map([
    ["sistema-info-caldera-gasoleo", calderaGas],
    ["sistema-info-caldera-gas", calderaGasoil],
    ["sistema-info-caldera-gas-condensacion", calderaGasCondensacion],
    ["sistema-info-aerotermia-aire-aire", aerotermiaAireAire],
    ["sistema-info-aerotermia-aire-agua", aerotermiaAireAgua],
    ["sistema-info-aire-acondicionado", aireAcondicionado],
    ["sistema-info-termo-electrico", termoElectrico]
]);

const Sistema = ({system, moreInfo}) => {
    return (
        <div className={styles.infoOption}>
            <h5>{system.nombre}</h5>
            <img className={styles.sistemaImg} src={systemImageMap.get(system.sistemaInfoName)} alt={system.nombre}/>
            <div className={styles.infoBox} onClick={() => moreInfo(system.sistemaInfoName)}>
                Más info:
            </div>
        </div>
    );
}

export default Sistema;