import React from "react";
import styles from "./Opcion.module.css"

const Opcion = ({ image, checked, value, onChange }) => {
    return (
        <div className={`col-md-3 d-flex flex-column align-items-center justify-content-end ${styles.opciones}`}>
            <img src={image} alt=""/>
            <div className="form-check">
                <input
                    className="form-check-input"
                    checked={checked}
                    type="radio"
                    name="tipo_vivienda"
                    id={value}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}

export default Opcion;